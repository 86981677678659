var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("BaseButtonRed", {
        staticClass: "scn-btn",
        attrs: { text: "Create Scenes", onClick: _vm.handleTextParse },
      }),
      _c(
        "div",
        { staticClass: "text-editor" },
        [
          _c("el-tiptap", {
            ref: "editor",
            attrs: {
              extensions: _vm.extensions,
              content: _vm.content,
              placeholder:
                "Write something or copy paste text with images from any website...",
            },
            on: { onUpdate: _vm.onUpdate },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }